
/** @class */
function GlobalData() {
    this.startupGid = undefined;

    this.loggedInUserId = undefined;

    this.lastPingTimestamps = [];
    this.lastPings = [];
    this.lastPingTimestamp = undefined;

    this.latestBlock = undefined;
    this.indexerProgressBlock = undefined;
    this.indexerProgressProcessing = false;
    this.lastIndexerProgressBlockNumber = 0;
    this.indexerProgressProcessingStalledCount = 0;
    this.indexerProgressProcessingLastTs = 0;
    this.blockProcessingRate = [];

    this.statsImgSrcNotEmpty = 0;

    this.latestMsgId = '0';
    this.wsUpdateCount = 0;
    this.wsUpdateCountActivity = 0;
    // this.wsUpdateCountRace = 0;
    // this.wsUpdateCountBattle = 0;
    this.wsUpdateCountGame = 0;
    this.wsUpdateCountLeaderboard = 0;
    this.wsUpdateCountOrder = 0;
    this.wsUpdateCountTxn = 0;
    // this.wsUpdateCountOther = 0;
    this.wsMsgCount = 0;
    this.reconnectOnPageEffect = false;
    this.pageEffectEnabled = true;
    this.activeTabName = 'tab_activity';
    this.activePillNameMya = 'tab_mya-profile';
    this.activePillNameMint = 'tab_mint-token';
    this.activePillNameDao = 'tab_dao-balance';
    this.activePillNameAbout = 'tab_about-our_story';
    // visible sub tab, can have multiple active
    this.activePillName = undefined;

    this.activePill2NameMyaBalance = 'tab_mya-balance-eth';
    this.activePill2Name = undefined;
    this.scrollTop = {};

    this.lastNftCreated = 0;
    this.lastNftExcluded = false;

    this.exchangeRateEthCache = undefined;
    this.uniswapCache = undefined;

    this.txRxUpdating = [false, false, false, false];
    this.activityCountUpdating = false;

    // keep track of which pattern has completed to allow other patterns to catch up
    this.patternCompletedList = {};

    this.setupLogMathRandomEnabled = false;
    this.mathRandomCounter = 0;
    this.logMathRandomEnabled = true;

    this.wsOutOfRange = false;

    this.lastWindowWidth = undefined;
    this.tokenomicsChart = undefined;

    this.wsActivityFilter = '';
    this.wsCollectionDefault = '';
    this.wsActivityExtraCollection = '';
    // set to 1 so it pings 9s later
    this.wsPingCount = 1;

    this.floorPrice = undefined;
    this.myDropsOfferCount = undefined;
    this.roomUserCountInfos = undefined;
    this.arenaUserCountInfos = undefined;

    // page changed variables
    this.dropUrlCheck = undefined;
    this.dropUrlCheckData = '';
    this.battleUrlCheck = undefined;
    this.battleUrlCheckData = '';
    this.raceUrlCheck = undefined;
    this.raceUrlCheckData = '';

    // this.showOwnerInfo = false;
    this.createMnemonicRid = 0;

    this.bobbobLoaded = {};
    this.loadLibProgress = 0;
    this.libsToLoad = 0;
    this.scriptsLoaded = {};

    // game
    this.gameAutoStart = true;
    this.gameStartupCreate = false;
}

module.exports = GlobalData;
